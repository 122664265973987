<template>
  <page-content>
    <h1 class="page-header">{{ $t('ordersInboundOrder') }}</h1>
    <b-alert :show="warning != null" variant="warning mt-2">{{ warning }}</b-alert>
    <b-alert :show="error != null" variant="danger mt-2">{{ error }}</b-alert>
    <b-alert v-if="success" :show="success" variant="success mt-2">{{ $t('orderInboundedSuccessfully') }}</b-alert>




    <b-card :title="$t('orders.purchaseOrderNumber')" class="mt-2">
      <b-form-group
        id="order-number"
        :label="$t('orderReferenceNumber')"
        label-for="order-number-input">
        <b-form-input
          id="order-number-input"
          v-model.trim="$v.rawForm.orderNumber.$model"
          :state="validateState('orderNumber')"
          required/>
        <b-form-invalid-feedback v-if="!$v.rawForm.orderNumber.required">{{
            $t('fieldIsRequired')
          }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.rawForm.orderNumber.maxLength">
          {{ $t('orderNumberShouldHaveAtMostLetters', {max: $v.rawForm.orderNumber.$params.maxLength.max}) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-card>

    <b-card :title="$t('note')" class="mt-2">
      <b-form>
        <b-form-group
          :label="$t('noteCapital')"
          label-for="note-input">
          <b-form-textarea
            id="note-input"
            v-model="rawForm.note"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('facility')" class="mt-2">
      <b-spinner v-if="!stockFacilityData"></b-spinner>
      <b-form v-else>
        <b-form-group
          :label="$t('stockFacility')"
          class="col mt-2"
          label-for="stock-facility">
          <b-form-select
            id="sale-facility"
            v-model="$v.rawForm.stockFacility.$model"
            :state="validateState('stockFacility')"
            class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in stockFacilityData.$resources" :key="v.fcy" :value="v.fcy">
              {{ `${v.fcy} ${v['fcy-name']}` }}
            </option>
            <b-form-invalid-feedback v-if="!$v.rawForm.stockFacility.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
          </b-form-select>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('currency')" class="mt-2">
      <b-form>
        <b-form-group
          :label="$t('currency:')"
          :placeholder="$t('currency')"
          label-for="currency">
          <b-form-select
            id="currency"
            v-model="$v.rawForm.currency.$model"
            :state="validateState('currency')"
            class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in currencies" :key="v.label" :value="v.value">{{ v.label }}</option>
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.rawForm.currency.required">{{
              $t('fieldIsRequired')
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('items')" class="mt-2">

      <div class="row">
        <b-table
          :fields="itemsTableFields"
          :items="this.rawForm.selectedItems"
          bordered
          fixed
          small
        >
          <template #bottom-row>
            <td>
              <vue-bootstrap-autocomplete
                v-model="rawForm.sku"
                :data="itemsData"
                :serializer="(item) => item.sku"
                class="border-0"
                @hit="onAddItem($event)"
                @input="onSkuInput"
                @paste="handlePaste"
              >
                <template v-slot:suggestion="{ data }">
                  {{ data.description ? `${data.sku}: ${data.description}` : data.sku }}
                </template>
              </vue-bootstrap-autocomplete>
              <notifications group="msg" position="bottom right"/>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </template>

          <template #cell(description)="data" >
            {{ data.item.description }}
          </template>

          <template #cell(priceNumber)="data">
            <b-form-input
              v-model="data.item.priceNumber"
            />
          </template>

          <template #cell(quantity)="data">
            <b-form-input
              v-model="data.item.quantity"
              min="1"
              type="number"
              value="1"
            />
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <a class="fa fa-x text-danger" href="#" type="button" @click="onDeleteItem(data)"></a>
            </div>
          </template>
        </b-table>
        <div style="font-size: 10px; color: dimgray;">*Please, select SKU from suggested options</div>
      </div>
    </b-card>

    <b-card class="mt-2" title="Expected date">
      <div class="px-1">
        <date-picker
          ref="expectedDate"
          v-model="expectedDate"
          :config="datePickerConfig"
          placeholder="Expected date"
          @dp-change="onStartChange($event)"
        />
      </div>
    </b-card>

    <b-button :disabled="creatingOrder || !rawForm.selectedItems.length" class="mt-2"
              style="width: 120px;" @click.prevent="onSubmit">
      <div v-if="creatingOrder" class="w-100">
        <b-spinner small></b-spinner>
      </div>
      <span v-else>
        {{ getButtonCaption }}
      </span>
    </b-button>
  </page-content>
</template>
<script>
import PageContent from '@/layout/PageContent.vue'
import { client } from '@/client/client'
import moment from 'moment'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'
import { debounce, throttle } from 'lodash'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)


export default {
  name: 'OrderPurchasePage',
  props: {
    rawFormInput: {
      type: Object,
      required: false,
    },
    inboundsEditMode: {
      type: Boolean,
      default: false, // Default value is `false` unless explicitly set
    },
    editPohnum: {
      type: String,
      default: null
    }
  },
  components: {PageContent, VueBootstrapAutocomplete},
  async mounted() {

    bus.$on('cpyChanged', () => {
      this.resetForm()
    })

    this.$nextTick(() => {
      this.getData()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  computed: {
    getButtonCaption() {
      if (this.inboundsEditMode) {
        return this.$t('edit');
      } else if (this.editPohnum) {
        return `${this.$t('edit')} ${this.editPohnum}`;
      } else {
        return this.$t('save');
      }
    },
  },
  data() {
    return {
      expectedDate: null,
      skuExpectedDate: null,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      itemsTableFields: [
        {
          key: 'sku',
        },
        {
          key: this.$t('description'),
        },
       /* {
          key: 'priceNumber',
        },*/
        {
          key: this.$t('quantity'),
        },
        {
          key: 'actions',
          label: '',
          thStyle: {width: '20px'},
        },
      ],
      loading: false,
      creatingOrder: false,
      readonly: false,
      warning: null,
      success: null,
      error: null,
      itemsData: [],
      stockFacilityData: null,
      currencies: [
        {label: 'CHF', value: 'CHF'},
        {label: 'EUR', value: 'EUR'},
        {label: 'GBP', value: 'GBP'},
        {label: 'DKK', value: 'DKK'},
        {label: 'USD', value: 'USD'},
      ],
      rawForm: {
        orderType: 'SON',
        selectedItems: [],
        sku: null,
        note: "",
        currency: 'EUR',
        stockFacility: null,
        orderNumber: null,
      },
      datePickerConfig: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        showClear: true,
        showClose: true,
        maxDate: false
      },
      form: {
        'items': [
          {
            'POH_POHFCY': '',
            'POH_CPY': '',
            'POH_CUR': '',
            'POH_ORDREF': '',
            'POH_ORDDAT': '',
            'POH_POHTEX1': '',
            'pos': []
          }
        ]
      }
    }
  },
  validations: {
    rawForm: {
      orderType: {
        required
      },
      stockFacility: {
        required
      },
      currency: {
        required
      },
      orderNumber: {
        required,
        maxLength: maxLength(16)
      },
      selectedItems: {
        minLength: minLength(1)
      }
    }
  },
  watch: {
    rawFormInput: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.initializeForm(newValue);
        }
      },
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    initializeForm(data) {

      //console.log(this.inboundsEditMode);

        this.rawForm.currency=data.items[0]?.poh_CUR || 'EUR';
        this.rawForm.orderNumber=data.items[0]?.poh_ORDREF || null;
        this.rawForm.stockFacility=data.items[0]?.poh_POHFCY || null;
        this.rawForm.note=data.items[0]?.poh_POHTEX1 || null;


        data.items.forEach(item => {
          item.pos.forEach(posItem => {
            const today = (posItem.poq_EXTRCPDAT      || null);
            this.expectedDate = today  ;
            this.skuExpectedDate= today     ;
            this.rawForm.selectedItems.push({
              quantity: posItem.poq_QTYUOM, // You can use the quantity from the posItem if needed
              sku: posItem.poq_ITMREF || null,
              description: posItem.poq_ITMDES || null,// Use 'defaultSKU' or other fallback if poq_ITMREF is missing
              priceNumber: posItem.poq_NETPRI_0 || null,
              skuExpectedDate: today
            });
          });
        });

    },
    async resetForm() {
      this.rawForm = {
        orderType: 'SON',
        selectedItems: [],
        sku: null,
        note: null,
        currency: 'EUR',
        stockFacility: null,
        orderNumber: null,
      }
      this.expectedDate = null
      this.skuExpectedDate = null
      this.stockFacilityData = null
      this.itemsData = []

      this.$v.$reset()
      await this.getData()
    },
    showAlert() {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000)
    },
    async getData() {
      if (this.$store.getters.currentCpy) {
        this.stockFacilityData = (await client.create_order().stock_facility_get({cpy: this.$store.getters.currentCpy})).data

        if (this.stockFacilityData.$resources.length < 2) {
          this.rawForm.stockFacility = this.stockFacilityData.$resources[0].fcy
        }
      }
    },
    onSkuInput() {
      this.rawForm.sku = this.rawForm.sku.toUpperCase()
      this.getItems()
    },
    handlePaste(event) {
      event.preventDefault()
      this.rawForm.sku = event.clipboardData.getData('text')
      this.onSkuInput()
    },
    getItems: debounce(async function () {
      try {
        this.loading = true

        const response = await client.create_order().items_get({
          cpy: this.$store.getters.currentCpy,
          sku: this.rawForm.sku,
          count: 10,
        })

        this.itemsData = response.data.$resources

        if (!this.itemsData.length) {
          this.$notify({
            group: 'msg',
            type: 'error',
            title: 'Failed to load data',
            text: 'Please, enter correct SKU code value',
            duration: 5000
          })
        }

        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
        this.$notify({
          group: 'msg',
          type: 'error',
          title: 'Failed to load data',
          text: 'An error occurred while fetching items data',
          duration: 5000
        })
      }
    }, 1000),
    onAddItem(item) {
      this.rawForm.sku = ''

      console.log(item.description)
      this.rawForm.selectedItems.push({
        ...item,
        quantity: 1,
        description: item.description,
        priceNumber: this.rawForm.currency ? item.price[this.rawForm.currency] : 0,
      })
    },
    onDeleteItem(data) {
      this.rawForm.selectedItems = this.rawForm.selectedItems.filter(item => data.item.sku !== item.sku)

      if (!this.rawForm.selectedItems.length) {
        this.expectedDate = null
        this.skuExpectedDate = null
      }
    },
    getDescendantProp(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    },
    validateState(name) {
      const {$dirty, $error} = this.getDescendantProp(this.$v.rawForm, name)
      return $dirty ? !$error : null
    },
    onStartChange(event) {
      if (event) {
        this.skuExpectedDate = event.date

      }
    },
    closeInboundsEdit() {
      // Close the panel by setting inboundsEdit to false
      this.modalEditInboundVisible = false;
      console.log("close inbound edit d");
    },
    onSubmit: throttle(async function () {
      try {
        this.$v.$touch()

        if (this.$v.$invalid) {
          this.loading = false
          this.creatingOrder = false
          return
        }


        this.form.items[0]['POH_POHFCY'] = this.rawForm.stockFacility
        this.form.items[0]['POH_CPY'] = this.$store.getters.currentCpy
        this.form.items[0]['POH_CUR'] = this.rawForm.currency
        this.form.items[0]['POH_ORDREF'] = this.rawForm.orderNumber
        this.form.items[0]['POH_ORDDAT'] = moment().format(this.REQUEST_DATE_FORMAT)
        this.form.items[0]['POH_POHTEX1'] = this.rawForm.note

        if(this.inboundsEditMode) {
          this.form.items[0]['POH_POHNUM'] = this.editPohnum;
        }

        this.form.items[0]['pos'] = this.rawForm.selectedItems.map((item) => {
          return {
            POQ_QTYUOM: item?.quantity,
            POQ_ITMDES: item?.description,
            POQ_ITMREF: `${this.$store.getters.currentCpy}_${item?.sku}`,
            POQ_EXTRCPDAT: this.expectedDate
            //POQ_EXTRCPDAT: this.skuExpectedDate ? this.skuExpectedDate.format(this.REQUEST_DATE_FORMAT) : null,
          }
        })
        this.error = null
        this.loading = true
        this.creatingOrder = true


        const response = this.inboundsEditMode ? await client.create_order().purchase_order_edit(this.form) : await client.create_order().purchase_order(this.form)



        //console.log("************************")
        if (response.data.status === '0') {
          this.error = response.data.message
          this.$notify({
            group: 'msg',
            type: 'error',
            text: response.data.message,
            duration: 5000
          })
        } else {
          this.showAlert()
          this.$notify({
            group: 'msg',
            type: 'success',
            text: 'Your order saved successfully! ' + response.data.pohnum,
            duration: 25000
          })

        }


        if (this.inboundsEditMode) {
          this.$emit('close-panel');
          this.closeInboundsEdit();
        }

        //await this.sleep(5000);
        this.loading = false
        this.creatingOrder = false

        await this.resetForm()

      } catch (error) {
        console.error('Error:', error)
        this.loading = false
        this.creatingOrder = false
        this.error = `${error.response.data.error.code}: ${error.response.data.error.detail}`
      }
    }, 5000)
  }
}
</script>

<style>
thead, tbody, tfoot, tr, td, th {
  text-align: left;
  width: 100px;
  vertical-align: middle;
}
</style>
