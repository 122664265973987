<template>
  <page-content style="overflow: hidden">
    <iframe :src="confluenceUrl"></iframe>
  </page-content>
</template>

<script>
import PageContent from "@/layout/PageContent.vue";

export default {
  name: 'HelpCenterPage',
  components: {PageContent},
  computed: {
    confluenceUrl() {
      return this.$route.meta?.confluenceUrl || ''
    }
  }
}
</script>

<style scoped>
iframe {
  position: relative;
  width: 99%;
  margin-top: 9px;
  height: inherit;
}






</style>
