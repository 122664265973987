import { auth } from './modules/auth'
import { orders } from './modules/orders'
import { invoice_fulfillments } from './modules/invoice_fulfillments'
import { order_delivery_detail } from './modules/order_delivery_detail'
import { returns } from './modules/returns'
import { dashboard_service } from './modules/dashboard_service'
import { backorders } from './modules/backorders'
import { batch_overview } from './modules/batch_overview'
import { inventory_items } from './modules/inventory_items'
import { bestsellers } from './modules/bestsellers'
import { stock_movements } from './modules/stock_movements'
import { service_quality } from './modules/service_quality'
import { service_carrier } from './modules/service_carrier'
import { reports_returns } from './modules/reports_returns'
import { reports_revenue } from './modules/reports_revenue'
import { reports_revenue_detail } from './modules/reports_revenue_detail'
import { reports_timeofgoodsreceipt } from './modules/reports_timeofgoodsreceipt'
import { reports_skurevenue } from './modules/reports_skurevenue'
import { reports_labelerrors } from './modules/reports_labelerrors'
import { reports_errors } from './modules/reports_errors'
import { reports_packaging } from './modules/reports_packaging'
import { process_flow } from './modules/process_flow'
import { role, roles } from './modules/roles'
import { users, user, cpys } from '@/client/modules/users'
import { create_order } from './modules/create_order'
import { change_address } from './modules/support'
import { profile } from './modules/profile'
import { orders_sage } from '@/client/modules/orders_sage'
import { order_events } from '@/client/modules/order-events'
import { inbounds } from '@/client/modules/inbounds'


export const client = {
  auth,
  orders,
  inbounds,
  dashboard_service,
  order_delivery_detail,
  order_events,
  returns,
  backorders,
  batch_overview,
  inventory_items,
  stock_movements,
  service_quality,
  service_carrier,
  reports_returns,
  reports_revenue,
  reports_revenue_detail,
  reports_timeofgoodsreceipt,
  reports_skurevenue,
  reports_labelerrors,
  reports_errors,
  bestsellers,
  process_flow,
  invoice_fulfillments,
  roles,
  role,
  change_address,
  users,
  user,
  cpys,
  create_order,
  profile,
  orders_sage,
  reports_packaging,
}
