<template>
  <page-content>
    <h1 class="page-header">{{ $t('backorders.page.header') }}</h1>
    <panel
      id="backorders-panel"
      :backButtonVisible="false"
      :helpButtonVisible="true"
      :dataToExport="backorders.data"
      :downloadData="downloadData"
      :lastUpdate="lastUpdate"
      :reloadButtonVisible="true"
      :title="$t('backorders')"
      filenameToExport="backordersdata.csv"
      @reload="load(null)"
    >
      <backorders
        ref="backorders"
        :data="backorders"
        :isLoading="isLoading"
        @filter="filter"
        @reload="load"
        @sort="sort"
        @filter-button="applyDateFilter"/>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>

<script>
import {client} from '@/client/client'
import PageContent from '../../../layout/PageContent'
import Backorders from '../components/Backorders'
import {debounce} from 'lodash'
import {bus} from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent, Backorders},
  data() {
    return {
      activeRequests: 0,
      lastUpdate: {},
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      backorders: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'order_created_at',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy
        },
        predefined: ''
      }
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.filters.cpy = data
      this.request.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
    const modal = this.$refs.modalDetail
    if (modal) {
      modal.$off('ok')
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async downloadData() {
      if (!this.isLoading) {
        await this.download()
        return this.returnsx.data
      }
    },
    async download() {
      this.activeRequests++
      await client.returns().all({
        per_page: 5000,
        page: 1,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        predefined: this.predefined,
        date_from: this.date_from,
        date_to: this.date_to,
        selectedFilter: this.selectedFilter,
        order_column: 'order_id',
        order_dir: this.order_dir,
        filters: this.filters
      })
        .then(response => {
          this.returnsx = response.data

        }).finally(() => {
          this.activeRequests--
        })
    },
    async getBackOrders() {
      this.activeRequests++
      this.request.cpy = this.$store.getters.currentCpy
      try {
        const response = await client.backorders().all(this.request)
        this.backorders = response.data
        this.lastUpdate = response.data.backOrderLastUpdate
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getBackOrders()
    },
    sort(event) {
      if (event[0].type === 'none') {
        this.request.order_column = event[0].field
        this.request.order_dir = 'desc'
      } else {
        this.request.order_column = event[0].field
        this.request.order_dir = event[0].type
      }
      this.getBackOrders()
    },
    filter: debounce(function (event) {
      if (event && event.columnFilters) {
        this.request.filters = {...event.columnFilters}
      }
      this.getBackOrders()
    }, 500),
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.date_from = null
      this.request.date_to = null
      this.request.order_column = 'order_created_at'
      this.request.order_dir = 'DESC'
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.getBackOrders()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    }
  }
}
</script>
