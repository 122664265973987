<template>
  <div>



    <vue-good-table
      ref="inbound-orders"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="{
          enabled: true,
          position: 'bottom',
          perPage: 20,
          perPageDropdown: null,
          perPageDropdownEnabled: true,
          dropdownAllowAll: false,
          mode: 'records',
          infoFn: null,
          jumpFirstOrLast : true
        }"
      :responsive="true"
      :rows="groupedOrders"
        :group-options="{
          enabled: true,
          collapsable: true,
          rowKey: 'order_id',
          headerRowComponent:'CustomGroupHeader'
      }"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="{
					enabled: false,
					selectOnCheckboxOnly: false,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
				}"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'order_date', type: 'desc'}
        }"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
      <template v-slot:table-row="props">
        <span v-if="props.column.field === 'closed'">
          <input
              :id="props.row.order_id"
              :checked=isInboundClosed(props.row.closed)
              class="form-check-input"
              type="checkbox"
              disabled
            />
        </span>
        <span v-else-if="props.column.field === 'more'">
          <b-dropdown
              dropup
              no-caret
              offset="150"
              toggle-class="btn-icon"
          >
            <template #button-content>
              <i class="fa fa-ellipsis-h"/>
            </template>

            <b-dropdown-item @click.prevent="doDeliveryNote(props.row)">
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                    style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-book"/>
                </span>
                <span>{{ $t('deliveryNote') }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="doEditInbound(props.row)" :disabled="!(props.row.closed === 'no' && props.row.pos_closed === 'no' && props.row.received === 'no') " >
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                    style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-edit"/>
                </span>
                <span>{{ $t('edit') }}</span>
              </div>
            </b-dropdown-item>
                <b-dropdown-item @click.prevent="deleteOrder(props.row)" :disabled="!(props.row.closed === 'no' && props.row.pos_closed === 'no' && props.row.received === 'no') " >
              <div class="d-flex align-items-center" style="gap: 4px">
                <span
                    style="display: flex; min-width: 15px; max-width: 15px; font-size: 12px;"
                >
                  <i class="fa fa-trash"/>
                </span>
                <span>{{ $t('delete') }}</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
      </template>
    </vue-good-table>


    <b-modal
        id="modal-detail-comment"
        ref="modal_comment_on_order"
        :title="$t('commentOnOrder')"
        :visible="modalCommentVisible"
        centered
        hide-header
        ok-only
        @ok="closeDeliveryNote"

        size="xl"
        @cancel="modalCommentVisible = false"

    >
      <panel id="comment" :title="$t('deliveryNote')" resizeable="true">
        <div v-if="downloadUrl">
          <a :href="downloadUrl" target="_blank">
            {{ this.selectedRow.order_id + ".pdf" }}
          </a>
        </div>
        <div v-else>
          {{ $t('Loading') }}...
        </div>
      </panel>

    </b-modal>


    <b-modal
        id="modal-edit-inbound"
        ref="modal-edit-inbound"
        :title="$t('commentOnOrder')"
        :visible="modalEditInboundVisible"
        centered
        hide-header
        ok-only
        @ok="closeEditInbound"
        :no-close-on-backdrop="true"
        size="xl"
        @cancel="modalEditInboundVisible = false"

    >

      <panel id="inboundsEdit" :title="$t('ordersInboundOrderDetail')" resizeable="true" >

          <div v-if="inboundsEdit">
            <OrderPurchasePage :rawFormInput="inboundsEdit" :inbounds-edit-mode=true :edit-pohnum="this.selectedRow.order_id" @close-panel="closeModal">


            </OrderPurchasePage>
          </div>
          <div v-else>
            {{ $t('Loading') }}...
          </div>

      </panel>




    </b-modal>
    <notifications group="msg" position="bottom right"/>
  </div>
</template>

<script>
import { client } from '@/client/client'
import OrderPurchasePage from "../pages/OrderPurchasePage.vue"
import Vue from "vue";
import Notifications from "vue-notification";

Vue.use(Notifications)

export default {
  name: 'Inbound-Orders',
  components: {
    OrderPurchasePage,
  },
  props: ['data', 'isLoading'],
  methods: {
    closeModal() {
      this.modalEditInboundVisible = false;
    }
    ,
    async getInboundForEdit() {
      this.inboundsEdit = null; // Reset the URL to show loading state
      try {

        const response =  (await client.inbounds().edit({pohnum: this.selectedRow.order_id}))
        //console.log("************");
        //console.log("Raw Response:", response);

        // If the response contains a data property with the JSON payload
        //console.log("Formatted JSON Response:", JSON.stringify(response.data, null, 2));
        //this.inboundsEdit = JSON.stringify(response.data, null, 2);
        //this.inboundsEdit =JSON.stringify(response.data, null, 2);
        this.inboundsEdit =response.data;

        //this.closeEditInbound();

      } catch (error) {
        console.error('Error fetching the download URL:', error);

      }
    },
    showAlert() {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000)
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async deleteOrder(event) {
      this.downloadUrl = null; // Reset the URL to show loading state
      try {
        //console.log(event.order_id);
    //    this.$emit('activate-loading');
        //this.isLoading=true;
        //console.log(this.selectedRow.order_id);

        this.showAlert()
        this.$notify({
          group: 'msg',
          type: 'success',
          text: 'Order ' + event.order_id + ' deleting',
          duration: 10000
        })



        const response =  await client.create_order().purchase_order_delete({cpy: this.$store.getters.currentCpy, pohnum: event.order_id})
            console.log(response);
        if (response.data.status === "1") {
         //
          //await this.sleep(10000);
          this.$emit('reload',  event)

          this.showAlert()
          this.$notify({
            group: 'msg',
            type: 'success',
            text: 'Order ' + event.order_id + ' deleted',
            duration: 25000
          })
        } else {
          this.showAlert()
          this.$notify({
            group: 'msg',
            type: 'success',
            text: 'Could not delete order ' + event.order_id + ' ',
            duration: 10000
          })
        }


      //  this.$emit('deactivate-loading');


      } catch (error) {
        console.error('Error fetching the download URL:', error);
        this.$emit('deactivate-loading');
      }
    },
    async fetchDownloadUrl() {
      this.downloadUrl = null; // Reset the URL to show loading state
      try {
        //console.log("Syste");
        //console.log(this.selectedRow.order_id);
        const response =  (await client.create_order().download_inbound_deliverynote_get({cpy: this.$store.getters.currentCpy, pohnum: this.selectedRow.order_id}))
        //console.log(response);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.downloadUrl = window.URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error fetching the download URL:', error);
      }
    },
    async updateRefunds(row, isChecked) {
      try {
        await client.returns().refunds({
          return_id: row.return_id,
          refunds: isChecked ? 1 : 0
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },

    closeDeliveryNote() {
      this.modalCommentVisible = false
    },
    closeEditInbound() {
      this.modalEditInboundVisible = false
    },
    doDeliveryNote(row) {
      this.selectedRow = row
      this.modalCommentVisible = true
    },
    doEditInbound(row) {
      console.log(row)
      this.selectedRow = row
      this.modalEditInboundVisible = true
    },
    isInboundClosed(refundsValue) {
      console.log(refundsValue === 'yes');
      return refundsValue === 'yes'
    },
    load: function (event) {
      this.$emit('reload', event)
    },
    onSortChange: function (event) {
      this.$emit('sort', event)
    },
    filterChanged: function (event) {
      this.$emit('filter', event)
    },
    filterButton(type) {
      this.$emit('filter-button', type)
    },
    reset() {
      this.$refs.returns.reset()
    }
  },
  watch: {
    modalCommentVisible(newValue) {
      if (newValue) {
        this.fetchDownloadUrl();
      }
    },
    modalEditInboundVisible(newValue) {
      if (newValue) {
        this.getInboundForEdit();
      }
    }
  },
  data() {
    return {
      selectedRow: {},
      modalCommentVisible: false,
      modalEditInboundVisible: false,
      downloadUrl: null,
      inboundsEdit: null,
      columns: [
        {
          label: '',
          field: 'more',
          filterable: false,
          sortable: false,
          width: '1%',
        },
        {
          label: 'ID',
          field: 'order_id',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ID'
          },
          group: true
        },
        {
          label: 'ORDER REF',
          field: 'order_ref',
          width: '15%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDER REF'
          }
        },
        {
          label: 'SKU',
          field: 'sku',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        },
        {
          label: 'SKU NAME',
          field: 'item_desc',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU NAME'
          }
        }, {
          label: 'ORDER DATE',
          field: 'order_date',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDERED AT'
          }
        }, {
          label: 'EXPECTED DATE',
          field: 'expected_rcp_date',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'EXPECTED DATE'
          }
        }, {
          label: 'ORDER QTY',
          field: 'order_qty',
          width: '10%',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          label: 'RECEIVED QTY',
          field: 'received_qty',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'RECEIVED QTY'
          }
        },
        {
          label: 'REMAINS QTY',
          field: 'remains',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'REMAINS QTY'
          }
        },
        {
          label: 'RECEIVED',
          field: 'received',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'RECEIVED'
          }
        },
        {
          label: 'CLOSED',
          field: 'closed',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'CLOSED'
          }
        },
        {
          label: 'POS. CLOSED',
          field: 'pos_closed',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'POS. CLOSED'
          }
        },
        {
          label: 'NOTE',
          field: 'note',
          width: '25%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'NOTE'
          }
        }

      ]
    }
  },
  computed: {
    groupedOrders() {
      if (!this.data || !this.data.data) {
        return [];
      }
      const groups = {};
      for (const item of this.data.data) {
        const groupKey = item.order_id;
        if (!groups[groupKey]) {
          groups[groupKey] = {
            ...item,
            sku: null,
            item_desc: null,
            order_qty: null,
            received_qty: null,
            remains: null,
            pos_closed: null,
            isExpanded: false,
            children: []
          };
        }
        groups[groupKey].children.push(item);
      }
      return Object.values(groups);
    }
  }
}
</script>

<style scoped>

.group-header {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.expand-icon {
  margin-right: 10px;
  font-weight: bold;
}

.vgt-table td {
  padding: 12px;
}
</style>
