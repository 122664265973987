<template>
  <div>
    <vue-good-table
      ref="backorders"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'details'">
            <a :style="{ color: props.row.comment_on_backorder !== '' ? '#8B0000' : '' }"
               class="fa fa-book" href="#"
               type="button"
               @click="doDetial(props.row)"></a>
          </span>
      </template>
    </vue-good-table>

    <div>
      <b-modal
        id="modal-detail"
        ref="modalDetail"
        :visible="modalVisible"
        centered
        hide-header
        ok-title="Save"
        size="lg"
        title="BootstrapVue"
        @cancel="cancelComment"
        @ok="saveComment"
      >
        <panel
          id="comment"
          :title="$t('backorders.page.panelTitle')"
          resizeable="true"
        >
          <BackorderComment :gridData="data.data" :selectedRow="selectedRow"/>
        </panel>
      </b-modal>
    </div>
  </div>
</template>

<script>
import BackorderComment from '../../../modules/backorders/components/BackorderComment'
import {bus} from '@/main'

export default {
  name: 'Backorders',
  components: {BackorderComment},
  props: ['data', 'isLoading'],
  data() {
    return {
      selectedRow: {},
      modalVisible: false,
      columns: [
        {
          label: '',
          field: 'details',
          filterable: false,
          sortable: false,
          width: '1%'
          //thClass: 'icon-magnifier'
        },
        {
          label: 'ORDER ID',
          field: 'order_id',
          width: '10%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'ORDER ID'
          }
        }, {
          label: 'INFO',
          field: 'item_zsperrsta_info',
          width: '5%',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'INFO'
          }
        }, {
          label: 'CPY',
          field: 'cpy',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'CPY'
          },

        }, {
          label: 'STOFCY',
          field: 'stofcy',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'STOFCY'
          },

        }, {
          label: 'SKU',
          field: 'sku',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        }, {
          label: 'CUSTOMER NAME',
          field: 'delivery_address_customer_name',
          width: '10%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER NAME'
          }
        },
        {
          label: 'NOTE',
          field: 'return_reason_desc',
          width: '2%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'NOTE'
          }
        }, {
          label: 'MH COMMENT',
          field: 'note_on_backorder',
          width: '40%',
          tdClass: 'text-center text-wrap',
          thClass: 'text-center text-wrap',
          filterOptions: {
            enabled: true,
            placeholder: 'MH COMMENT'
          }
        }, {
          label: 'CUSTOMER COMMENTS',
          field: 'comment_on_backorder',
          width: '40%',
          tdClass: 'text-center text-wrap',
          thClass: 'text-center text-wrap',
          filterOptions: {
            enabled: true,
            placeholder: 'CUSTOMER COMMENTS'
          }
        },
        {
          label: 'ORDERED QTY',
          field: 'ordered_quantity',
          width: '2%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'ordered_quantity'
          }
        },
        {
          label: 'SHORTAGE',
          field: 'shortage_quantity',
          width: '2%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'shortage_quantity'
          }
        },
        {
          label: 'CREATED',
          field: 'order_created_at',
          type: 'date',
          dateInputFormat: 'yyyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          width: '5%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records',
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'order_created_at', type: 'desc'}
      }
    }
  },
  created() {
    bus.$on('onSelectedRowChange', (data) => {
      this.selectedRow.comment_on_backorder = data
    })
  },
  beforeDestroy() {
    bus.$off('onSelectedRowChange')
  },
  methods: {
    cancelComment(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.modalVisible = false
    },
    saveComment(bvModalEvent) {
      bvModalEvent.preventDefault()
      bus.$emit('saveCommentEvent')
      this.modalVisible = false
    },
    doDetial(row) {
      this.selectedRow = row
      //this.$refs.modalDetail.show();
      this.modalVisible = true
      // this.$refs.modalDetail.show();
    },
    load(event) {
      this.$emit('reload', event)
    },
    onSortChange(event) {
      this.$emit('sort', event)
      if (event) {
        this.$emit('sort', event)
      }
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    filterButton(type) {
      this.$emit('filter-button', type)
    },
    reset() {
      this.$refs.backorders.reset()
    }
  },
}
</script>

<style scoped>
.refunded {
  color: #18c471;
}
</style>
