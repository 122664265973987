<template>
  <div>
    <!-- BEGIN #header -->
    <div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
      <!-- BEGIN navbar-header -->
      <div class="navbar-header">
        <button v-if="appOptions.appSidebarTwo" class="navbar-mobile-toggler" type="button"
                v-on:click="toggleSidebarEndMobile">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <logo/>

        <button v-if="appOptions.appTopMenu && !appOptions.appSidebarNone" class="navbar-mobile-toggler" type="button"
                v-on:click="toggleTopMenuMobile">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
        </button>
        <button v-if="appOptions.appTopMenu && appOptions.appSidebarNone" class="navbar-mobile-toggler" type="button"
                v-on:click="toggleTopMenuMobile">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button v-if="appOptions.appHeaderMegaMenu" class="navbar-mobile-toggler" type="button"
                v-on:click="toggleHeaderMegaMenuMobile">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
        </button>
        <button v-if="!appOptions.appSidebarNone" class="navbar-mobile-toggler" type="button"
                v-on:click="toggleSidebarMobile">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- END navbar-header -->


      <!-- BEGIN header-nav -->
      <div class="navbar-nav">
        <div class="navbar-item" style="margin-right: auto;">
          <div
               >
            {{ $t('footerCopyright', {year: year}) }}
          </div>
        </div>
        <!--div class="col-md-1">
          <div>
  <multiselect v-model="stofcValue" deselect-label="Can't remove this value" track-by="name" label="name"  :options="options" :searchable="false" :allow-empty="false" :multiple="true"  :limit="3"  hidden="true">
  </multiselect>
</div-->

        <!--div class="col-md-4">
          <treeselect  
            :multiple="true"
            :options="options"
            placeholder="Select your favourite(s)..."
            v-model="stofcValue"
            />
          <treeselect-value :value="value" />
        </div-->

        <div class="col-md-2 p-2">
          <b-form-select v-model="$i18n.locale" class="form-select" @input="onLocaleChange">
            <b-form-select-option value="en">English</b-form-select-option>
            <b-form-select-option value="de">Deutsch</b-form-select-option>
          </b-form-select>
        </div>

        <div class="col-md-5" hidden="true">
          <treeselect
            v-model="stofcValue"
            :flat="false"
            :limit="3"
            :max-height="300"
            :multiple="true"
            :options="options"
            :placeholder="$t('header.selectYourFavourites')"
            :valueConsistsOf="valueConsistsOf"
            v-on:input="changeStofcy(stofcValue)"
          />

        </div>

        <div class="col-md-4">
          <v-select v-model="selectedCpy" :options="mandantList" class="cpy-select" label="label"
                    placeholder="Search"
                    style="font-size: 13px; font-weight: 500;"
                    @input="changeCpy($event)">
          </v-select>
        </div>

        <header-mega-menu v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>
        <b-nav-item-dropdown class="navbar-item navbar-user dropdown" menu-class="me-1" no-caret
                             right toggle-class="navbar-link dropdown-toggle d-flex align-items-center">
          <template slot="button-content">
            <img alt="" src="../../assets/img/user/user-12.jpg"/>
            <span class="d-none d-md-inline">{{ fullName }}</span> <b class="caret"></b>
          </template>
          <b-dropdown-item to="/profile">{{ $t('profile') }}</b-dropdown-item>
          <b-dropdown-item href="javascript:;" @click="logout">{{ $t('header.logOut') }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <div v-if="appOptions.appSidebarTwo" class="navbar-divider d-none d-md-block"></div>
        <div v-if="appOptions.appSidebarTwo" class="navbar-item d-none d-md-block">
          <a class="navbar-link icon" href="javascript:;" v-on:click="toggleSidebarEnd">
            <i class="fa fa-th"></i>
          </a>
        </div>
      </div>
      <notifications group="msg" position="bottom right"/>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>


<script>
import { requestManager } from '@/assets/requestManager.js'
import AppOptions from '../../config/AppOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import Logo from './Logo.vue'
import { client } from '@/client/client'
import { bus } from '@/main'
import Treeselect from '@riophae/vue-treeselect'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

const CryptoJS = require('crypto-js')


export default {
  name: 'Header',
  components: {
    vSelect,
    HeaderMegaMenu,
    Logo,
    Treeselect,
    //Multiselect,
  },
  data() {
    return {
      valueConsistsOf: 'LEAF_PRIORITY',
      stofcValue: null,
      options: [{
        id: 'a',
        label: 'a',
        children: [{
          id: 'aa',
          label: 'aa',
        }, {
          id: 'ab',
          label: 'ab',
        }],
      }, {
        id: 'b',
        label: 'b',
      }, {
        id: 'c',
        label: 'c',
      }],
      appOptions: AppOptions,
      selectedCpy: this.$store.getters.currentCpy,
    }
  },
  async mounted() {

    this.resetTimer()
    window.addEventListener('mousemove', this.resetTimer)
    window.addEventListener('keydown', this.resetTimer)
    window.addEventListener('mousedown', this.resetTimer)
    window.addEventListener('touchstart', this.resetTimer)

    if (this.$route.path !== '/login') {
      if (!this.$store.state.user) {
        try {
          const response = await client.auth().me()
          let user = response.data

          this.$store.commit('currentUser', user)

          const storedSelectedCpy = this.getDecryptedLocalStorage('storedSelectedCpy', 'secret')
          if (storedSelectedCpy) {
            this.selectedCpy = storedSelectedCpy
          } else {
            this.$notify({
              group: 'msg',
              text: 'Please select mandant from the list to get data',
              duration: 5000
            })
          }

          this.$watch(
            () => this.$store.getters.currentCpy,
            (newVal, oldVal) => {
              if (newVal !== oldVal) {
                this.selectedCpy = newVal
                this.changeCpy(this.selectedCpy)
              }
            }
          )

          this.changeCpy(this.selectedCpy)

        } catch (error) {
          console.error('Error:', error)
        }
      } else {
        this.selectedCpy = this.currentCpy
        this.changeCpy(this.selectedCpy)
      }
    }
  },
  computed: {
    fullName() {
      return this.$store.getters.userFullName
    },
    email() {
      return this.$store.getters.userEmail
    },
    cpyList() {
      return this.$store.getters.cpy
    },
    currentCpy() {
      return this.$store.getters.currentCpy
    },
    mandantList() {
      if (this.$store.getters.user === undefined) {
        return []
      }
      const sortedMandants = this.sortMandants(this.$store.getters.user.cpys.slice())

      return sortedMandants.map(item => ({
        ...item,
        label: `${item.name} [${item.cpy}]`
      }))
    }
  },
  beforeDestroy() {
    clearTimeout(this.logoutTimer)
    window.removeEventListener('mousemove', this.resetTimer)
    window.removeEventListener('keydown', this.resetTimer)
    window.removeEventListener('mousedown', this.resetTimer)
    window.removeEventListener('touchstart', this.resetTimer)
  },
  methods: {
    setEncryptedLocalStorage(key, value, secret) {
      const encryptedValue = this.encryptValue(value, secret)
      localStorage.setItem(key, encryptedValue)
    },
    encryptValue(value, secret) {
      return CryptoJS.AES.encrypt(value, secret).toString()
    },
    getDecryptedLocalStorage(key, secret) {
      const encryptedValue = localStorage.getItem(key)
      if (encryptedValue) {
        return this.decryptValue(encryptedValue, secret)
      }
      return null
    },
    decryptValue(encryptedValue, secret) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secret)
      return bytes.toString(CryptoJS.enc.Utf8)
    },
    sortMandants(mandants) {
      return mandants.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.optionsx.push(tag)
      this.valuex.push(tag)
    },
    getStofc(data, searchCpy) {
      const stofcyArr = []
      const cpys = data.cpys
      for (let i = 0; i < cpys.length; i++) {
        if (searchCpy === cpys[i].cpy) {
          var stofcy = cpys[i].stofcy
          for (let x = 0; x < stofcy.length; x++) {
            stofcyArr.push(stofcy[x].fcy)
          }
        }
      }
      return stofcyArr
    },
    logout() {
      requestManager.cancelAllRequests()
      this.$store.commit('resetState')

      localStorage.removeItem('token')
      //localStorage.clear();

      if (this.$route.path !== '/login') {
        this.$router.push({path: '/login'})
      }

      window.location.reload()
    },
    toggleSidebarMobile() {
      this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled
    },
    toggleSidebarEnd() {
      this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled
    },
    toggleSidebarEndMobile() {
      this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled
    },
    toggleTopMenuMobile() {
      this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled
    },
    toggleHeaderMegaMenuMobile() {
      this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled
    },
    checkForm: function (e) {
      e.preventDefault()
      this.$router.push({path: '/extra/search'})
    },
    changeStofcy(stofcy) {
      // this.$store.commit('selectedStofcy', stofcy);
      bus.$emit('stofcyChanged', stofcy)
      //bus.$emit('cpyChanged', '01');
    },
    changeCpy(cpy) {
      if (cpy) {
        if (typeof cpy === 'string') {
          this.$store.commit('currentCpy', cpy)
          bus.$emit('cpyChanged', cpy)
          this.setEncryptedLocalStorage('storedSelectedCpy', cpy, 'secret')
        } else {
          this.$store.commit('currentCpy', cpy.cpy)
          bus.$emit('cpyChanged', cpy.cpy)
          this.setEncryptedLocalStorage('storedSelectedCpy', cpy.cpy, 'secret')
        }

        var options = []
        var data = this.$store.getters.user.cpys
        data.forEach(function (item) {
          var option = {
            id: item.cpy,
            label: item.name + ' [' + item.cpy + ']',
            children: []
          }

          item.stofcy.forEach(function (subItem) {
            var childOption = {
              id: subItem.fcy,
              label: subItem.name + ' [' + subItem.fcy + ']'
            }
            option.children.push(childOption)
          })

          options.push(option)
        })
        this.options = options
      }
    },
    resetTimer() {
      clearTimeout(this.logoutTimer)
      this.logoutTimer = setTimeout(() => {
        this.logout()
      }, 30 * 60 * 1000)
    },
    onLocaleChange(value) {
      localStorage.setItem('last-locale', value)
      this.$router.go()
    }
  }
}
</script>

