<template>
  <page-content>
    <h1 class="page-header">{{ $t('ordersCreateOrder') }}</h1>
    <b-alert :show="warning != null" variant="warning mt-2">{{ warning }}</b-alert>
    <b-alert :show="error != null" variant="danger mt-2">{{ error }}</b-alert>
    <b-alert v-if="success" :show="success" variant="success mt-2">{{ $t('orderCreatedSuccessfully') }}</b-alert>
    <b-card :title="$t('details')" class="mt-2">
      <b-form-group
        id="order-type"
        :label="$t('orderType')"
        label-for="order-type-input"
      >
        <b-form-select
          id="order-type-input"
          v-model="$v.rawForm.orderType.$model"
          :state="validateState('orderType')"
          class="form-select"
          required
        >
          <option value="SON">{{ $t('b2cOrder') }}</option>
          <option value="SOB2B">{{ $t('b2bOrder') }}</option>
        </b-form-select>
        <b-form-invalid-feedback v-if="!$v.rawForm.orderType.required">{{
            $t('fieldIsRequired')
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="rawForm.orderType === 'SOB2B'"
        id="order-number"
        :label="$t('orderNumber')"
        label-for="order-number-input">
        <b-form-input
          id="order-number-input"
          v-model.trim="$v.rawForm.orderNumber.$model"
          :state="validateState('orderNumber')"
          required>

        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.rawForm.orderNumber.required">{{
            $t('fieldIsRequired')
          }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.rawForm.orderNumber.maxLength">
          {{ $t('orderNumberShouldHaveAtMostLetters', {max: $v.rawForm.orderNumber.$params.maxLength.max}) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="country"
        :label="$t('country')"
        :state="validateState('country')"
        label-for="country-input">
        <multiselect v-if="countries" id="country-input" v-model="$v.rawForm.country.$model"
                     :allow-empty="false"
                     :class="{ invalid: $v.rawForm.country.$dirty && $v.rawForm.country.$error }"
                     :options="countries"
                     :placeholder="$t('selectOne')" :searchable="true" deselect-label="Can't remove this value"
                     label="name"
                     track-by="name">
        </multiselect>
        <div v-if="$v.rawForm.country.$dirty && $v.rawForm.country.$error" class="invalid-feedback d-block m-0">
          {{ $t('fieldIsRequired') }}
        </div>
      </b-form-group>
    </b-card>
    <b-card :title="$t('addressCapital')" class="mt-2">
      <b-form novalidate>
        <div class="row mt-2">
          <b-form-group
            id="address-name1"
            :label="$t('name1')"
            class="col"
            label-for="address-input-name1">
            <b-form-input
              id="address-input-name1"
              v-model="$v.rawForm.address.name1.$model"
              :state="validateState('address.name1')"
              required>

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.name1.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.name1.maxLength">
              {{ $t('nameShouldHaveAtMostLetters', {max: $v.rawForm.address.name1.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-name2"
            :label="$t('name2')"
            class="col"
            label-for="address-input-name2">
            <b-form-input
              id="address-input-name2"
              v-model="$v.rawForm.address.name2.$model"
              :state="validateState('address.name2')"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.name2.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.name2.maxLength">
              {{ $t('name2ShouldHaveAtMostLetters', {max: $v.rawForm.address.name2.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-street"
            :label="$t('street')"
            class="col-3"
            label-for="address-input-street">
            <b-form-input
              id="address-input-street"
              v-model="$v.rawForm.address.street.$model"
              :state="validateState('address.street')"
              required
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.street.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.street.maxLength">
              {{ $t('streetShouldHaveAtMostLetters', {max: $v.rawForm.address.street.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-streetnr"
            :label="$t('streetNumber')"
            class="col-2"
            label-for="address-input-streetnr">
            <b-form-input
              id="address-input-streetnr"
              v-model="$v.rawForm.address.streetNr.$model"
              :state="validateState('address.streetNr')"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.streetNr.maxLength">
              {{ $t('streetNumberShouldHaveAtMostLetters', {max: $v.rawForm.address.streetNr.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-street2"
            :label="$t('street2')"
            class="col-2"
            label-for="address-input-street2">
            <b-form-input
              id="address-input-street2"
              v-model="$v.rawForm.address.street2.$model"
              :state="validateState('address.street2')"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.street2.maxLength">
              {{ $t('street2ShouldHaveAtMostLetters', {max: $v.rawForm.address.street2.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-zip"
            :label="$t('zipCode')"
            class="col-2"
            label-for="address-input-zip">
            <b-form-input
              id="address-input-zip"
              v-model="$v.rawForm.address.zip.$model"
              :state="validateState('address.zip')"
              required
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.zip.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.zip.maxLength">
              {{ $t('zipShouldHaveAtMostLetters', {max: $v.rawForm.address.zip.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-city"
            :label="$t('city')"
            class="col-3"
            label-for="address-input-city">
            <b-form-input
              id="address-input-city"
              v-model="$v.rawForm.address.city.$model"
              :state="validateState('address.city')"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.city.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.city.maxLength">
              {{ $t('cityShouldHaveAtMostLetters', {max: $v.rawForm.address.city.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="row mt-2">

        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-email"
            :label="$t('email')"
            class="col"
            label-for="address-input-email">
            <b-form-input
              id="address-input-email"
              v-model="$v.rawForm.address.email.$model"
              :state="validateState('address.email')"
              type="email"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.email.maxLength">
              {{ $t('emailShouldHaveAtMostLetters', {max: $v.rawForm.address.email.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-phone"
            :label="$t('phone')"
            class="col"
            label-for="address-input-phone">
            <b-form-input
              id="address-input-phone"
              v-model="$v.rawForm.address.phone.$model"
              :state="validateState('address.phone')"
            >

            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.rawForm.address.phone.maxLength">
              {{ $t('phoneShouldHaveAtMostLetters', {max: $v.rawForm.address.phone.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-form>
    </b-card>

    <b-card :title="$t('note')" class="mt-2">
      <b-form>
        <b-form-group
          :label="$t('noteCapital')"
          label-for="note-input">
          <b-form-textarea
            id="note-input"
            v-model="rawForm.note"
            rows="3"
          >

          </b-form-textarea>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('facility')" class="mt-2">
      <b-spinner v-if="!saleFacilityData || !stockFacilityData"></b-spinner>
      <b-form v-else>
        <b-form-group
          class="col"
          label="Sale Facility"
          label-for="sale-facility">
          <b-form-select
            id="sale-facility"
            v-model="$v.rawForm.saleFacility.$model"
            :state="validateState('saleFacility')"
            class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in saleFacilityData.$resources" :key="v.fcy" :value="v.fcy">
              {{ `${v.fcy} ${v['fcy-name']}` }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.rawForm.saleFacility.required">{{
              $t('fieldIsRequired')
            }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('stockFacility')"
          class="col mt-2"
          label-for="stock-facility">
          <b-form-select
            id="sale-facility"
            v-model="$v.rawForm.stockFacility.$model"
            :state="validateState('stockFacility')"
            class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in stockFacilityData.$resources" :key="v.fcy" :value="v.fcy">
              {{ `${v.fcy} ${v['fcy-name']}` }}
            </option>
            <b-form-invalid-feedback v-if="!$v.rawForm.stockFacility.required">{{
                $t('fieldIsRequired')
              }}
            </b-form-invalid-feedback>
          </b-form-select>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('orders.delivery-detail.shipper')" class="mt-2">
      <b-spinner v-if="!shipperData"></b-spinner>
      <b-form v-else>
        <b-form-group
          :label="$t('orders.delivery-detail.shipper')"
          class="col mt-2"
          label-for="shipper">
          <b-form-select
            id="shipper"
            v-model="$v.rawForm.shipper.$model"
            :state="validateState('shipper')"
            class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in shipperData.$resources" :key="v.shipper" :value="v.shipper">
              {{ `${v.shipper} ${v.description}` }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.rawForm.shipper.required">{{
              $t('fieldIsRequired')
            }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="shipperServiceCodes"
          :label="$t('serviceCode')"
          class="col mt-2"
          label-for="shipper-service">
          <select
            id="shipper-service"
            v-model="rawForm.shipperServiceCode"
            class="form-select"
          >
            <option v-for="(v) in shipperServiceCodes" :key="v.code" :value="v.code">{{
                `${v.code} ${v.description}`
              }}
            </option>
          </select>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('currency')" class="mt-2">
      <b-form>
        <b-form-group
          :label="$t('currency:')"
          :placeholder="$t('currency')"
          label-for="currency">
          <b-form-select id="currency"
                         v-model="$v.rawForm.currency.$model"
                         :state="validateState('currency')"
                         class="form-select"
          >
            <option :value="null">{{ $t('choose') }}</option>
            <option v-for="(v) in currencies" :key="v.label" :value="v.value">{{ v.label }}</option>
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.rawForm.currency.required">{{
              $t('fieldIsRequired')
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card :title="$t('items')" class="mt-2">

      <div class="row">
        <b-table :fields="itemsTableFields" :items="this.rawForm.selectedItems" bordered fixed small>
          <template #bottom-row>
            <td>
              <vue-bootstrap-autocomplete
                v-model="rawForm.sku"
                :data="itemsData"
                :min-matching-chars="0"
                :serializer="(item) => item.sku"
                class="border-0"
                @hit="onAddItem($event)"
                @input="onSkuInput"
                @paste="handlePaste"
              >
                <template slot="suggestion" slot-scope="{ data }">
                  {{ data.description ? `${data.sku}: ${data.description}` : data.sku }}
                </template>
              </vue-bootstrap-autocomplete>
              <notifications group="msg" position="bottom right"/>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </template>

          <template #cell(priceNumber)="data">
            <b-form-input
              v-model="data.item.priceNumber"
              @input="val => data.item.priceNumber = val.replace(',', '.')"
            >

            </b-form-input>
          </template>

          <template #cell(quantity)="data">
            <b-form-input
              v-model="data.item.quantity"
              min="1"
              type="number"
              value="1"
            >

            </b-form-input>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <a class="fa fa-x text-danger" href="#" type="button" @click="onDeleteItem(data.index)"></a>
            </div>
          </template>
        </b-table>
        <div style="font-size: 10px; color: dimgray;">*Please, select SKU from suggested options</div>
      </div>
    </b-card>

    <b-card class="mt-2" title="Attachments">
      <b-form>
        <b-form-group
          label="Instruction"
          label-for="instruction-input">
          <b-form-textarea
            id="instruction-input"
            v-model="rawForm.instruction"
            :style="instructionInvalid && 'border: 1px solid red'"
            rows="2"
            @change="saveInstruction($event)"
            @focus="setInstruction"
          >
          </b-form-textarea>
        </b-form-group>
      </b-form>
      <div v-if="instructionRequired" :style="instructionInvalid && 'color: red;'"
           style="font-size: 10px; color: dimgray;">*Please, add instruction
      </div>

      <div class="upload-files" style="margin-top: 20px;">
        <span>Upload files (max 10 attachements)</span>
        <file-pond
          ref="pond"
          :accepted-file-types="acceptedFileTypes"
          :allowFileEncode="true"
          :allowFileSizeValidation="true"
          :allowMultiple="true"
          :allowReorder="true"
          :checkValidity="true"
          fileValidateTypeLabelExpectedTypes="Expected file extensions: .pdf, .doc, .docx, .xls, .xlsx, .txt, .rtf, .jpg .gif, .png or any image extensions"
          label-idle="Drop files here..."
          max-file-size="5MB"
          maxFiles="10"
          v-bind:files="rawForm.files"
          v-on:updatefiles="handleInputFile"
        />
      </div>
    </b-card>

    <b-button :disabled="creatingOrder || !rawForm.selectedItems.length" class="mt-2"
              style="width: 100px;"
              @click.prevent="onSubmit">
      <div v-if="creatingOrder" class="w-100">
        <b-spinner small></b-spinner>
      </div>
      <span v-else>
        {{ $t('createOrder') }}
      </span>
    </b-button>
  </page-content>
</template>
<script>
import PageContent from '@/layout/PageContent.vue'
import { client } from '@/client/client'
import moment from 'moment'
import { getCountries } from 'country-from-iso2'
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'
import { debounce, throttle } from 'lodash'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileEncode)

Vue.use(Notifications)


export default {
  name: 'OrderChangePage',
  components: {
    PageContent, VueBootstrapAutocomplete, FilePond
  },
  async mounted() {
    await this.getData()

    bus.$on('cpyChanged', () => {
      this.shipperData = null
      this.saleFacilityData = null
      this.stockFacilityData = null
      this.itemsData = []
      this.rawForm.selectedItems = []
      this.getData()
    })

    this.countries = getCountries().map((country) => {
      return {
        iso2: country.cca2,
        name: country.name.common
      }
    })
    this.countries = this.countries.filter((country) => {
      return ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH'].includes(country.iso2)
    })
    this.$nextTick(() => {
      this.getData()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  data() {
    return {
      acceptedFileTypes: [
        'image/*', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain, text/rtf'
      ],
      attachments: [],
      instructionInvalid: false,
      itemsTableFields: [
        {
          key: 'sku',
        },
        {
          key: this.$t('description'),
        },
        {
          key: 'priceNumber',
        },
        {
          key: this.$t('quantity'),
        },
        {
          key: 'actions',
          label: '',
          thStyle: {width: '20px'},
        },
      ],
      loading: false,
      creatingOrder: false,
      readonly: false,
      warning: null,
      success: null,
      error: null,
      itemsData: [],
      countries: null,
      shipperData: null,
      saleFacilityData: null,
      stockFacilityData: null,
      currencies: [
        {label: 'CHF', value: 'CHF'},
        {label: 'EUR', value: 'EUR'},
        {label: 'GBP', value: 'GBP'},
        {label: 'DKK', value: 'DKK'},
        {label: 'USD', value: 'USD'},
      ],
      rawForm: {
        address: {
          name1: null,
          name2: null,
          street: null,
          street2: null,
          streetNr: null,
          zip: null,
          phone: null,
          email: null,
          city: null,
        },
        orderType: 'SON',
        selectedItems: [],
        sku: null,
        note: null,
        currency: 'EUR',
        country: null,
        stockFacility: null,
        saleFacility: null,
        shipper: null,
        orderNumber: null,
        shipperServiceCode: null,
        files: null,
        instruction: '',
      },
      form: {
        'items': [
          {
            'SOH_SALFCY': '',
            'SOH_SOHTYP': '',
            'SOH_ORDDAT': '',
            'SOH_SOHNUM': '',
            'SOH_STOFCY': '',
            'SOH_CUR': '',
            'SOH_BPCCRY': '',
            'SOH_BPDNAM_0': '',
            'SOH_BPDNAM_1': '',
            'SOH_BPDADDLIG_0': '',
            'SOH_BPDPOSCOD': '',
            'SOH_ZTEL': '',
            'SOH_ZEMAIL': '',
            'SOH_BPDCTY': '',
            'SOH_INST': '',
            'pos': [],
            'attachments': []
          }
        ]
      }
    }
  },
  validations: {
    rawForm: {
      orderType: {
        required
      },
      stockFacility: {
        required
      },
      saleFacility: {
        required
      },
      currency: {
        required
      },
      shipper: {
        required
      },
      orderNumber: {
        required: requiredIf((vm) => {
          vm.orderType === 'SOB2B'
        }),
        maxLength: maxLength(16)
      },
      country: {
        required
      },
      address: {
        name1: {
          required,
          maxLength: maxLength(20)
        },
        name2: {
          required,
          maxLength: maxLength(30)
        },
        street: {
          required,
          maxLength: maxLength(50)
        },
        street2: {
          maxLength: maxLength(50)
        },
        city: {
          required,
          maxLength: maxLength(50)
        },
        streetNr: {
          maxLength: maxLength(50)
        },
        zip: {
          required,
          maxLength: maxLength(8)
        },
        phone: {
          maxLength: maxLength(22)
        },
        email: {
          maxLength: maxLength(50)
        },
      },
      selectedItems: {
        minLength: minLength(1)
      }
    }
  },
  computed: {
    shipperServiceCodes() {
      const shipper = this.shipperData.$resources.find(r => r.shipper === this.shipper)
      return shipper ? shipper.services : null
    },
    instructionRequired() {
      return this.attachments.length > 0 && !this.rawForm.instruction
    }
  },
  methods: {
    setInstruction() {
      this.instructionInvalid = false
      if (localStorage.getItem('instructionText')) {
        this.rawForm.instruction = localStorage.getItem('instructionText')
      }
    },
    saveInstruction(event) {
      localStorage.setItem('instructionText', event)
    },
    handleInputFile(files) {
      this.attachments = files.map(file => ({
        SOA_ZATTACHNAME: file.filename,
        SOA_ZATTACH: file.getFileEncodeBase64String()
      }))
    },
    showAlert() {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000)
    },
    async resetForm() {
      this.rawForm = {
        address: {
          name1: null,
          name2: null,
          street: null,
          street2: null,
          streetNr: null,
          zip: null,
          phone: null,
          email: null,
          city: null,
        },
        orderType: 'SON',
        selectedItems: [],
        sku: null,
        note: null,
        currency: 'EUR',
        country: null,
        stockFacility: null,
        saleFacility: null,
        shipper: null,
        orderNumber: null,
        shipperServiceCode: null,
        files: null,
        instruction: '',
      }
      this.shipperData = null
      this.saleFacilityData = null
      this.stockFacilityData = null
      this.itemsData = []
      this.rawForm.selectedItems = []
      this.instructionInvalid = false
      this.$v.$reset()

      if (this.$refs.pond) {
        this.$refs.pond.removeFiles()
      }

      await this.getData()
    },
    async getData() {
      try {
        if (this.$store.getters.currentCpy) {
          this.saleFacilityData = (await client.create_order().sale_facility_get({cpy: this.$store.getters.currentCpy})).data

          if (this.saleFacilityData.$resources.length < 2) {
            this.rawForm.saleFacility = this.saleFacilityData.$resources[0].fcy
          }

          this.stockFacilityData = (await client.create_order().stock_facility_get({cpy: this.$store.getters.currentCpy})).data

          if (this.stockFacilityData.$resources.length < 2) {
            this.rawForm.stockFacility = this.stockFacilityData.$resources[0].fcy
          }

          this.shipperData = (await client.create_order().shippers_get({
            cpy: this.$store.getters.currentCpy,
            count: 45
          })).data

        }
      } catch (error) {
        console.error('Error:', error)
      }
    },
    onSkuInput() {
      this.rawForm.sku = this.rawForm.sku.toUpperCase()
      this.getItems()
    },
    handlePaste(event) {
      event.preventDefault()
      this.rawForm.sku = event.clipboardData.getData('text')
      this.onSkuInput()
    },
    getItems: debounce(function () {
      this.loading = true

      client.create_order().items_get({
        cpy: this.$store.getters.currentCpy,
        sku: this.rawForm.sku,
        count: 20,
      })
        .then((response) => {
          this.itemsData = response.data.$resources
          if (!this.itemsData.length) {
            this.$notify({
              group: 'msg',
              type: 'error',
              title: 'Failed to load data',
              text: 'Please, enter correct SKU code value',
              duration: 5000
            })
          }
          this.loading = false
        })
    }, 1000),
    onAddItem(item) {
      this.rawForm.sku = ''

      this.rawForm.selectedItems.push({
        ...item,
        quantity: 1,
        priceNumber: this.rawForm.currency ? item.price[this.rawForm.currency] : 0,
      })
    },
    onDeleteItem(index) {
      this.rawForm.selectedItems.splice(index, 1)
    },
    getDescendantProp(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    },
    validateState(name) {
      const {$dirty, $error} = this.getDescendantProp(this.$v.rawForm, name)
      return $dirty ? !$error : null
    },
    onSubmit: throttle(async function () {
        try {
          this.$v.$touch()
          if (this.$v.$invalid || this.instructionRequired) {
            if (this.instructionRequired) {
              this.instructionInvalid = true
            }
            this.loading = false
            this.creatingOrder = false
            return
          }

          const street = `${this.rawForm.address.street}${this.rawForm.address.streetNr ? ' ' + this.rawForm.address.streetNr : ''}`

          this.form.items[0]['SOH_SALFCY'] = this.rawForm.saleFacility
          this.form.items[0]['SOH_STOFCY'] = this.rawForm.stockFacility
          this.form.items[0]['SOH_ORDDAT'] = moment().format('YYYYMMDD')
          this.form.items[0]['SOH_SOHNUM'] = this.rawForm.orderNumber
          this.form.items[0]['SOH_CUR'] = this.rawForm.currency
          this.form.items[0]['SOH_CPY'] = this.$store.getters.currentCpy
          this.form.items[0]['SOH_BPCCRY'] = this.rawForm.country.iso2
          this.form.items[0]['SOH_BPDNAM_0'] = this.rawForm.address.name1
          this.form.items[0]['SOH_BPDNAM_1'] = this.rawForm.address.name2
          this.form.items[0]['SOH_BPDADDLIG_0'] = street
          this.form.items[0]['SOH_BPDADDLIG_1'] = this.rawForm.address.street2
          this.form.items[0]['SOH_BPDPOSCOD'] = this.rawForm.address.zip
          this.form.items[0]['SOH_ZTEL'] = this.rawForm.address.phone
          this.form.items[0]['SOH_BPDCTY'] = this.rawForm.address.city
          this.form.items[0]['SOH_ZEMAIL'] = this.rawForm.address.email
          this.form.items[0]['SOH_ZCPY'] = this.$store.getters.currentCpy
          this.form.items[0]['SOHTYP'] = 'SON'
          this.form.items[0]['SOH_BPCORD'] = this.rawForm.orderNumber
          this.form.items[0]['SOH_BPCCAT'] = 'K001'
          this.form.items[0]['SOH_SOHTEX1'] = this.rawForm.note
          this.form.items[0]['SOH_SOHTYP'] = this.rawForm.orderType
          this.form.items[0]['SOH_BPTNUM'] = this.rawForm.shipper
          this.form.items[0]['SOH_INST'] = this.rawForm.instruction
          this.form.items[0]['pos'] = this.rawForm.selectedItems.map((item) => {
            return {
              SOQ_ITMREF: `${this.$store.getters.currentCpy}_${item.sku}`,
              SOQ_DES1AXX: item.description,
              SOQ_QTY: item.quantity,
              SOQ_GROPRI: item.priceNumber
            }
          })
          this.form.items[0]['attachments'] = this.attachments

          this.error = null
          this.loading = true
          this.creatingOrder = true

          const response = await client.create_order().create_order(this.form)
          if (response.data.status === '0') {
            this.error = response.data.message
          } else {
            this.showAlert()
          }

          this.loading = false
          this.creatingOrder = false

          const orderId = response.data.orderid

          this.$notify({
            group: 'msg',
            type: 'success',
            text: `Your order with ID ${orderId} has been created successfully! <br/><div onclick="navigator.clipboard.writeText('${orderId}');" style="display: flex; align-items: start; justify-content: center; width: 100%;"><i class="fa fa-copy" style="cursor: pointer; width: 25px; height: 25px;"></i><span>Copy order ID</span></div>`,
            duration: 7000
          })

          await this.resetForm()
        } catch (error) {
          console.error('Error:', error)
          this.$notify({
            group: 'msg',
            type: 'error',
            text: error,
            duration: 3000
          })
          this.loading = false
          this.creatingOrder = false
          this.error = `${error.response.data.error.code}: ${error.response.data.error.detail}`
        }
      }, 5000
    )
  }
}
</script>

<style>
#country > div > div.multiselect.invalid > div.multiselect__tags {
  border-color: #ff5b57 !important;
}

thead, tbody, tfoot, tr, td, th {
  text-align: left;
  width: 100px;
  vertical-align: middle;
}
</style>
