<template>
  <page-content>
    <h1 class="page-header">{{ $t('bestseller.page.header') }}</h1>
    <panel id="bestseller-overview" :title="$t('overview')">
      <div class="row p-b-15">
        <div class="col-md-7">
          <div id="bestseller-filter-control" class="row">
            <div class="col-md-3">
              <select
                v-model="selectedFilter"
                class="form-select"
              >
                <option
                  v-for="(filter, index) in predefinedFilters"
                  :key="filter.name + index"
                  :value="filter.filter"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateFrom"
                v-model="dateFrom"
                :config="configs.start"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdFrom')"
                v-on:dp-change="onStartChange"/>
            </div>
            <div class="col-md-3 padding-left-0" style="position: relative;">
              <date-picker
                ref="dateTo"
                v-model="dateTo"
                :config="configs.end"
                :disabled="selectedFilter !== 'custom'"
                :placeholder="$t('createdTo')"
                v-on:dp-change="onEndChange"/>
            </div>
            <div class="col-md-3 padding-left-0">
              <b-button
                class="button btn-success margin-right-5"
                @click="handleFilterButtonClick"
              >
                {{ $t('filter') }}
              </b-button>
              <b-button
                class="button btn-warning"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">Loading...</span>
          </slot>
        </div>
        <div v-else class="col-sm-7">
          <div class="card border-0 text-truncate mb-3 bg-gray-200">
            <div class="card-body">
              <div class="mb-3 text-black">
                <b class="mb-3">{{ $t('bestseller.page.bestSellingProducts') }}</b>
                <span class="ms-2">
                  <i v-b-popover.hover="$t('popover.bestSellingProducts')" class="fa fa-info-circle"/>
                </span>
              </div>
              <div
                v-for="product in bestsellers.products"
                :key="product.sku"
                class="d-flex mb-2"
              >
                <div class="d-flex align-items-center">
                  <i class="fa fa-circle text-green fs-8px me-2"/>
                  {{ product.sku }} - {{ product.description }}
                </div>
                <div class="d-flex align-items-center ms-auto">
                  <div class="w-50px text-end ps-2 fw-bold">
                    {{ product.total_ordered }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
    <panel
      id="bestseller-panel"
      :reloadButtonVisible="true"
      :helpButtonVisible="true"
      :title="$t('bestsellers')"
      @reload="load(null)"
    >
      <bestsellers
        ref="bestsellers"
        :data="bestsellers"
        :isLoading="isLoading"
        @filter="filter"
        @reload="load"
        @sort="sort"
        @filter-button="applyDateFilter"/>
    </panel>
    <notifications group="msg" position="bottom right"/>
  </page-content>
</template>
<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import Bestsellers from '../components/Bestsellers'
import { debounce } from 'lodash'
import { bus } from '@/main'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  components: {PageContent, Bestsellers},
  data() {
    return {
      activeRequests: 0,
      REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
      dateFrom: null,
      dateTo: null,
      configs: {
        start: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false
        },
        end: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false
        }
      },
      selectedFilter: '',
      predefinedFilters: [
        {name: this.$t('today'), filter: 'today'},
        {name: this.$t('yesterday'), filter: 'yesterday'},
        {name: this.$t('lastWeek'), filter: 'last_7'},
        {name: this.$t('last30Days'), filter: 'last_30'},
        {name: this.$t('last6Months'), filter: 'last_6m'},
        {name: this.$t('lastYear'), filter: 'last_year'},
        {name: this.$t('custom'), filter: 'custom'}
      ],
      bestsellers: {},
      isLoading: false,
      request: {
        cpy: this.$store.getters.currentCpy,
        per_page: 20,
        page: 1,
        date_from: null,
        date_to: null,
        order_column: 'sku',
        order_dir: 'DESC',
        filters: {
          cpy: this.$store.getters.currentCpy
        },
        predefined: ''
      }
    }
  },
  async mounted() {
    await this.getDefaultData()
    bus.$on('cpyChanged', (data) => {
      this.request.filters.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    },
    selectedFilter(newValue) {
      if (newValue !== 'custom') {
        this.applyDateFilter()
      }
    }
  },
  methods: {
    async getDefaultData() {
      this.activeRequests++
      try {
        const response = await client.auth().me()
        this.selectedFilter = response.data.defaultDateFilter

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    async getBestSellers() {
      this.activeRequests++
      this.request.cpy = this.$store.getters.currentCpy
      try {
        const response = await client.bestsellers().all(this.request)
        this.bestsellers = response.data
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getBestSellers()
    },
    sort(event) {
      if (event[0].type === 'none') {
        this.request.order_column = event[0].field
        this.request.order_dir = 'desc'
      } else {
        this.request.order_column = event[0].field
        this.request.order_dir = event[0].type
      }
      this.getBestSellers()
    },
    filter: debounce(function (event) {
      if (event && event.columnFilters) {
        this.request.filters = {...event.columnFilters}
      }
      this.getBestSellers()
    }, 500),
    resetFilters() {
      this.dateFrom = null
      this.dateTo = null
      this.request.predefined = ''
      this.request.date_from = null
      this.request.date_to = null
      this.request.order_column = 'sku'
      this.request.order_dir = 'DESC'
      this.request.filters = {cpy: this.$store.getters.currentCpy}
      this.selectedFilter = this.$store.getters.userDefaultDateFilter
    },
    applyDateFilter() {
      switch (this.selectedFilter) {
        case 'today':
          this.request.date_from = new Date()
          this.request.date_to = new Date()
          break
        case 'yesterday':
          var yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          this.request.date_from = yesterday
          this.request.date_to = yesterday
          break
        case 'last_7':
          var last_7 = new Date()
          last_7.setDate(last_7.getDate() - 7)
          this.request.date_from = last_7
          this.request.date_to = new Date()
          break
        case 'last_30':
          var last_30 = new Date()
          last_30.setDate(last_30.getDate() - 30)
          this.request.date_from = last_30
          this.request.date_to = new Date()
          break
        case 'last_6m':
          var last_6m = new Date()
          last_6m.setMonth(last_6m.getMonth() - 6)
          this.request.date_from = last_6m
          this.request.date_to = new Date()
          break
        case 'last_year':
          var last_year = new Date()
          last_year.setFullYear(last_year.getFullYear() - 1)
          this.request.date_from = last_year
          this.request.date_to = new Date()
          break
        case 'custom':
          this.request.date_from = this.dateFrom.format(this.REQUEST_DATE_FORMAT)
          this.request.date_to = this.dateTo.format(this.REQUEST_DATE_FORMAT)
          break
      }
      this.getBestSellers()
    },
    handleFilterButtonClick() {
      if (this.selectedFilter === 'custom' && (!this.dateFrom || !this.dateTo)) {
        this.$notify({
          group: 'msg',
          type: 'error',
          text: 'Created From and Created To fields must be filled to apply custom date filter',
          duration: 5000
        })
      } else {
        this.applyDateFilter()
      }
    },
    onStartChange(e) {
      this.dateFrom = e.date
    },
    onEndChange(e) {
      this.dateTo = e.date
    }
  }
}
</script>
